import OrderContext from "@context/OrderContext";
import StoreContext from "@context/StoreContext";
import { Add } from "@mui/icons-material";
import { Alert, Fab, Link, useTheme, Zoom } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import GenericPage from "@templates/GenericPage";
import { toTitleCase } from "@utils/misc";
import { useNavigate } from "react-router-dom";
import { default as React, FC, useContext, useEffect, useState } from "react";
import { Article, useGetAllArticlesLazyQuery, useGetArticlesBySubCategoryLazyQuery, useGetRosterLazyQuery } from "../../graphql/types";
import LineGrid from "./LineGrid";

const LinesPage: FC = () => {
  const { order, loading: orderLoading } = useContext(OrderContext);
  const [articles, setArticles] = useState<Article[]>([]);
  const [currentOrderRosterExpired, setCurrentOrderRosterExpired] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { category, subCategory, categoryHierarchyNode, subCategoryHierarchyNode, dc } = useParams();
  const [getAllArticles, { loading: allArticleLoading, fetchMore, networkStatus }] = useGetAllArticlesLazyQuery();
  const [getArticlesBySubCategory, { loading: articlesBySubLoading }] = useGetArticlesBySubCategoryLazyQuery();
  const { storeId } = useContext(StoreContext);
  const [getRosters] = useGetRosterLazyQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const breadcrumbs = [];
    category
      ? breadcrumbs.push({
        to: dc == null ? `/catalogue/${category}/${categoryHierarchyNode}` : `/catalogue/${category}/${categoryHierarchyNode}/${dc}`,
        text: toTitleCase(category),
      })
      : breadcrumbs.push({
        to: `/lines/`,
        text: "All Lines",
      });

    subCategory &&
      breadcrumbs.push({
        to: `/lines/${category}/${categoryHierarchyNode}/${subCategory}/${subCategoryHierarchyNode}/${dc}`,
        text: toTitleCase(subCategory),
      });
  }, []);

  useEffect(() => {
    if (!storeId || !order.id) return;
    getRosters({
      variables: {
        offset: 0,
        limit: 50,
        siteNumber: storeId,
      },
      nextFetchPolicy: "cache-first",
      onCompleted: data => {
        const rosters = data.storeOrderRostersBySite?.items || [];
        const currentOrderRoster = rosters?.find(roster => roster.id === order.rosterId);
        setCurrentOrderRosterExpired(!currentOrderRoster);
      },
    });
  }, [storeId, order.id]);

  useEffect(() => {
    if (!order.id) return;
    if (!subCategoryHierarchyNode) {
      getAllArticles({
        variables: {
          locationNumber: storeId || "",
          dc: order?.sourceOfSupplyId || "",
          offset: 0,
          limit: 50,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
          const articles = data?.articles?.items || [];
          const totalCount = data?.articles?.totalCount || 0;
          setArticles(articles);
          setTotalCount(totalCount);
        },
      });
    } else {
      getArticlesBySubCategory({
        variables: {
          locationNumber: storeId || "",
          dc: order?.sourceOfSupplyId || "",
          subCategoryHierarchyNode,
          offset: 0,
          limit: -1,
        },
        onCompleted: data => {
          const articles = data?.articlesBySubCategory?.items || [];
          setArticles(articles);
        },
      });
    }
  }, [subCategoryHierarchyNode, order]);

  const theme = useTheme();
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

  return (
    <>
      {!order?.id && !orderLoading && (
        <Alert severity="warning" sx={{ mb: 2, mt: 2, marginLeft: '32px', marginRight: '32px' }}>
          You don't have any order in progress,&nbsp;
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/roster/`);
            }}
          >
            click here
          </Link>
          &nbsp; to select a roster
        </Alert>
      )}
      <GenericPage
        title={subCategory ? toTitleCase(subCategory) : "All Lines"}
        maxWidth="xl"
        fab={
          <Zoom
            in={selectionModel.length > 0}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: theme.transitions.duration.leavingScreen,
            }}
            style={{
              transitionDelay: `${selectionModel.length > 0 ? theme.transitions.duration.leavingScreen : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab
              type="button"
              onClick={() => { }}
              sx={{
                position: "absolute",
                bottom: {
                  xs: "5.5rem",
                  md: "1rem",
                },
                right: {
                  xs: "2rem",
                  md: "1rem",
                },
              }}
              variant="extended"
              color="secondary"
            >
              <Add sx={{ mr: 1 }} />
              Add to Order
            </Fab>
          </Zoom>
        }
      >
        {currentOrderRosterExpired && order?.id && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Selected roster for current order expired, &nbsp;
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                navigate(`/order/edit/${order.id}/`);
              }}
            >
              click here
            </Link>
            &nbsp; to select a new roster
          </Alert>
        )}
        {order?.id && !orderLoading &&
          <LineGrid
            loading={articlesBySubLoading || allArticleLoading || networkStatus === 3}
            currentOrderRosterExpired={currentOrderRosterExpired}
            subCategory={subCategory || ""}
            articles={articles}
            subCategoryHierarchyNode={subCategoryHierarchyNode || ""}
            totalCount={totalCount}
            onPaginationChange={paginationModel => {
              const { page, pageSize } = paginationModel;
              fetchMore({
                variables: {
                  offset: page * pageSize,
                  limit: pageSize,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => (!fetchMoreResult ? prevResult : fetchMoreResult),
              });
            }}
            onRowSelect={(model: GridRowSelectionModel) => setSelectionModel(model)}
            selectionModel={selectionModel}
          />
        }
      </GenericPage></>
  );
};

export default LinesPage;
