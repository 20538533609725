import CataloguePage from "@components/Catalogue";
import LinesPage from "@components/Lines";
import OrderEdit from "@components/Orders/OrderEdit";
import OrdersList from "@components/Orders/OrdersList";
import OrderView from "@components/Orders/OrderView";
import PrintPage from "@components/Print";
import RosterPage from "@components/Roster";
import DocumentationPage from "@components/Documentation";
import SupportPage from "@components/Support";
import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import ForecastPage from "@components/Forecast/StoreForecast";
import DcForecastPage from "@components/Forecast/DCForecast";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/orders" replace />} />
      <Route path="/lines" element={<LinesPage />} />
      <Route path="/lines/:category/:categoryHierarchyNode/:subCategory/:subCategoryHierarchyNode/:dc" element={<LinesPage />} />
      <Route path="/lines/:category/:categoryHierarchyNode/:subCategory/:subCategoryHierarchyNode" element={<LinesPage />} />
      <Route path="/catalogue/:category/:dc" element={<CataloguePage />} />
      <Route path="/catalogue/:category" element={<CataloguePage />} />
      <Route path="/catalogue/:departmentId/:category/:categoryHierarchyNode/:dc" element={<CataloguePage />} />
      <Route path="/catalogue/:departmentId/:category/:categoryHierarchyNode" element={<CataloguePage />} />
      <Route path="/roster" element={<RosterPage />} />
      <Route path="/orders" element={<OrdersList />} />
      <Route path="/order/view/:orderId/:orderStatus" element={<OrderView />} />
      <Route path="/order/edit/:orderId" element={<OrderEdit />} />
      <Route path="/print" element={<PrintPage />} />
      <Route path="/documentation" element={<DocumentationPage />} />
      <Route path="/support" element={<SupportPage />} />
      <Route path="/store-forecast" element={<ForecastPage />} />
      <Route path="/dc-forecast" element={<DcForecastPage />} />
    </Routes>
  );
};

export default Index;
